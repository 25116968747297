<template>
  <div>
    <div class="title1">安徽案例</div>

    <div class="title2">
      <img class="bg" src="@/assets/images/common/titleIcon1.png" />
      <span>安徽案例</span>
    </div>

    <div class="desc">
      安徽省构建了“1+16”城市生命线安全监测运行体系，搭建了城市生命线安全工程省级监管平台，覆盖16个设区市的燃气、桥梁、供水、排水防涝等领域，实现对近3万公里地下管网和325座桥梁实时监测，率先实现省域全覆盖，并在二期场景中拓展至燃气用户终端、消防、电梯、窨井盖、水环境治理、热力、综合管廊、路面塌陷、轨道交通、城市照明等其他重点领域，实现县域覆盖。
      安徽省城市生命线安全工程通过建立7*24小时值守制度，相关部门协同联动，搭建“前端感知一专业研判一预警溯源一协同处置”新型风险防范体系，成为全国生命线工程推广典范。
    </div>

    <img style="display: block; width: calc(100% - 64px); margin: 32px auto" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/applicationCase/anHuiCase/01.png" alt="" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 125px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.desc {
  width: calc(100% - 64px);
  padding: 0 32px;
  text-indent: 2em;
  line-height: 25px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
}
</style>
