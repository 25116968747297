<template>
  <div class="title1">一机制</div>
  <div class="title2">
    <img src="@/assets/images/common/titleIcon1.png" />
    <span>一机制：城市安全运行协同联调机制</span>
  </div>

  <img style="width: calc(100% - 64px); padding: 0 32px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/mechanism/01.png" alt="" />

  <div class="title2">
    <img src="@/assets/images/common/titleIcon2.png" />
    <span>预警分级原则</span>
  </div>

  <div class="text">按照当前警情可能导致城市安全事故性质、当前风险的态势发展程度、事故发生后可能影响的严重程度等因素，将城市安全运行风险预警共分为三级。</div>

  <div class="wrapBox">
    <div class="box box1">
      <div class="header">三级</div>
      <div class="body">预计可能会发生一般突发事件，事件可能会来临，事态有扩大的趋势。</div>
    </div>
    <div class="box box2">
      <div class="header">二级</div>
      <div class="body">预计会发生一般及以上突发事件，事件即 将临近事态正在逐步扩查，后果比较严重。</div>
    </div>
    <div class="box box3">
      <div class="header">一级</div>
      <div class="body">预计将要发生一般及以上突发事件,事件会随时发生，事态正在不断蔓延，后果很严重。</div>
    </div>
    <div class="cycle">预警分级</div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 120px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 32px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.text {
  width: calc(100% - 64px);
  padding: 0 32px;
  text-indent: 2em;
  line-height: 25px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
  margin-bottom: 48px;
}
.wrapBox {
  width: calc(100% - 64px);
  margin: 0 auto 80px;
  display: flex;
  justify-content: space-between;
  position: relative;
  .box {
    width: 127px;
    height: 156px;
    padding: 8px;
    border: 1px solid #9a9794;
    position: relative;
    .header {
      width: 90px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      position: absolute;
      top: -15px;
      left: 26px;
      color: #584a83;
      background: #f3f0f7;
      font-weight: 600;
      font-size: 17px;
      letter-spacing: 2px;
    }
    .body {
      padding-top: 16px;
      font-size: 12px;
      font-weight: 100;
      letter-spacing: 2px;
    }
  }
  .cycle {
    position: absolute;
    bottom: -38px;
    width: 100%;
    height: 75px;
    line-height: 75px;
    background: #83619b;
    letter-spacing: 2px;
    font-size: 17px;
    font-weight: 600;
    color: white;
    text-align: center;
    border-radius: 50%;
  }
}
</style>
