<template>
  <div>
    <div class="title1">生产安全专项</div>

    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>尾矿库解决方案</span>
    </div>
    <div class="desc">
      采用天空地一体化多源立体监测预警体系，融合北斗高度定位、机载激光雷达、地基INSAR等多种监测技术，对尾矿库的坝体位移、浸润线、库水位、降用量等重要参数进行在线监测，并自动分析各指标运行变化规律，实现对尾矿库安全隐患预警预报，有效提升尾矿库安全事故预防和应急管理能力，助力防范化解尾矿库安全风险。
    </div>
    <div style="width: calc(100% - 64px); padding: 12px 32px 36px; display: flex; justify-content: space-between">
      <img style="width: 355px; height: 230px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/productionSafetyProject/01.png" alt="" />
      <img style="width: 355px; height: 230px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/productionSafetyProject/02.png" alt="" />
    </div>

    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>工贸行业粉尘涉爆解决方案</span>
    </div>
    <div class="desc">
      基于前端物联主机，依托运营商专线，通过集成传感器技术、数据采集与处理技术及预警算法，对粉尘涉爆企业除尘设备设施的关键安全参数（如灰斗内部温度、进出口风压差等）进行实时在线监测和研判，实现安全生产风险实时监测、动态预警、智能研判和及时处置，推动粉尘防爆安全治理向事前预防转型，督促和服务企业主动落实安全风险防控主体责任，有效化解重大安全风险事故发生。
    </div>
    <div style="width: calc(100% - 64px); padding: 12px 32px 36px; display: flex; justify-content: space-between">
      <img style="width: 355px; height: 230px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/productionSafetyProject/03.png" alt="" />
      <img style="width: 355px; height: 230px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/productionSafetyProject/03.png" alt="" />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 150px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.desc {
  width: calc(100% - 64px);
  padding: 0 32px;
  text-indent: 2em;
  line-height: 23px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
}
</style>
