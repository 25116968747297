<template>
  <div class="cmptContainer">
    <div class="menu">
      <img class="logo" src="@/assets/images/common/logo2.png" />
      <div class="menuItem" :class="{ active: activeIndex === '/home' }" @click="handleSelect('/home')">首页</div>
      <div class="menuItem" :class="{ active: activeIndex === '/serverSystem' }" @click="handleSelect('/serverSystem')">服务体系</div>
      <div class="menuItem" :class="{ active: activeIndex === '/solution' }" @click="handleSelect('/solution')">解决方案</div>
      <div class="menuItem" :class="{ active: activeIndex === '/applicationCase' }" @click="handleSelect('/applicationCase')">应用案例</div>
      <div class="menuItem" :class="{ active: activeIndex === '/aboutUs' }" @click="handleSelect('/aboutUs')">关于我们</div>
    </div>
    <!-- <el-menu :default-active="activeIndex" mode="horizontal" :ellipsis="false" @select="handleSelect">
      <el-menu-item index="/home"> 首页 </el-menu-item>
      <el-menu-item index="/serverSystem"> 服务体系 </el-menu-item>
      <el-menu-item index="/solution"> 解决方案 </el-menu-item>
      <el-menu-item index="/applicationCase"> 应用案例 </el-menu-item>
      <el-menu-item index="/aboutUs"> 关于我们 </el-menu-item>
    </el-menu> -->
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const activeIndex = ref()
const route = useRoute()
const router = useRouter()

onMounted(() => {
  activeIndex.value = route.path || '/home'
})

const handleSelect = (e) => {
  activeIndex.value = e
  router.push(e)
}
</script>

<style lang="scss" scoped>
.cmptContainer {
  // width: 100vw;
  height: 90px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #584a83;
  .logo {
    width: 180px;
    height: 60px;
  }
  .menu {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menuItem {
      letter-spacing: 2px;
      font-size: 17px;
      font-weight: 600;
      color: white;
      cursor: pointer;
      &.active {
        color: #ce9147;
      }
    }
  }
}
</style>
