<template>
  <div>
    <div class="title1">公共安全专项</div>
    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>智慧消防解决方案</span>
    </div>

    <div class="desc">
      智慧消防解决方案主要依托公共安全领长或顶级专家团队，创新消防服务模式与技术以对企业的消防安全损失赔付为兜底，从消防设施、工作管理、宣传教育、应急响应、保险保障五方面，向社会单位提供线上线下相结合的消防安全托管服务。
    </div>
    <img style="width: calc(100% - 64px); padding: 12px 32px 36px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/specialProjectOfPublicSecurity/01.png" alt="" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 150px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.desc {
  width: calc(100% - 64px);
  padding: 0 32px;
  text-indent: 2em;
  line-height: 23px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
}
</style>
