<template>
  <div>
    <div class="title1">生产安全专项</div>

    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>森林火灾解决方案</span>
    </div>
    <div class="desc">
      根据全速率视频监控技术、移动传输技术、国家GIS地理信息技术、图像智能识别技术等先进技术，结合AI大模型算法平台以及安防监拉系统平台，对森林防火前端感知设备的传输数据进行汇聚管理、AI智能分析、智慧处理、预警推送、处置联动等，能快速、准确有效发现火灾隐患并实时预警，实现森林火灾全方位、立体化综合监测及预警处置。
    </div>
    <img style="width: calc(100% - 64px); padding: 12px 32px 36px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/specialProgramForNaturalDisasters/01.png" alt="" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 150px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.desc {
  width: calc(100% - 64px);
  padding: 0 32px;
  text-indent: 2em;
  line-height: 23px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
}
</style>
