<template>
  <div>
    <div class="title1">城市安全运营平台</div>
    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>城市安全风险监测运营平台</span>
    </div>

    <p class="text1">
      基于城市生命线、公共安全、自然灾害、生产安全等多场景安全需求，搭建运行监测、数据运营、协同机制融合一体的城市安全风险监测运营平台，实现城市安全耦合风险评估、精准预警研判、模型高效赋能、应急联动处置、事件科学决策，促进信息化、智能化与城市安全深度融合，推动跨部门跨层级互联互通、信息共享和业务协同，支撑城市安全监测运营服务，提升城市安全风险防范能力。
    </p>
    <div class="itemWrap">
      <div class="item">
        <img src="@/assets/images/solution/citySafetyOperationPlatform/01.png" alt="" />
        <div class="desc">汇集数据</div>
      </div>
      <div class="item">
        <img src="@/assets/images/solution/citySafetyOperationPlatform/02.png" alt="" />
        <div class="desc">统计分析</div>
      </div>
      <div class="item">
        <img src="@/assets/images/solution/citySafetyOperationPlatform/03.png" alt="" />
        <div class="desc">挖掘关键数据</div>
      </div>
      <div class="item">
        <img src="@/assets/images/solution/citySafetyOperationPlatform/04.png" alt="" />
        <div class="desc">可视化展示</div>
      </div>
      <div class="item">
        <img src="@/assets/images/solution/citySafetyOperationPlatform/05.png" alt="" />
        <div class="desc">辅助研判分析</div>
      </div>
    </div>

    <div style="display: flex; justify-content: center">
      <img style="width: 90px; height: 72px" src="@/assets/images/solution/citySafetyOperationPlatform/06.png" alt="" />
    </div>

    <img style="width: calc(100% - 64px); padding: 12px 32px 0" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citySafetyOperationPlatform/07.png" alt="" />

    <div class="title2" style="margin-bottom: 6px">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>城市安全风险预警联动处置平台</span>
    </div>

    <img style="width: calc(100% - 64px); padding: 12px 32px 0" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citySafetyOperationPlatform/08.png" alt="" />

    <div class="itemWrap1">
      <div class="item">
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citySafetyOperationPlatform/09.png" alt="" />
        <div class="desc">专业</div>
      </div>
      <div class="item">
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citySafetyOperationPlatform/09.png" alt="" />
        <div class="desc">快速</div>
      </div>
      <div class="item">
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citySafetyOperationPlatform/09.png" alt="" />
        <div class="desc">可靠</div>
      </div>
      <div class="item">
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citySafetyOperationPlatform/09.png" alt="" />
        <div class="desc">协同</div>
      </div>
    </div>

    <div class="title2" style="margin-bottom: 6px">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>城市安全风险综合运营运维平台管理</span>
    </div>

    <div class="text1">
      综合运维平台通过整合工单处理、设备与资产管理、以及巡检维修等关键功能，帮助用户及运行监测中心实现供排燃桥场景的高效、安全、可靠运行，实现运维工作的智能化、精细化管理，有效提升运营效率与服务质量，确保城市基础设施稳定运行，保障公共安全。
    </div>

    <img style="width: calc(100% - 64px); padding: 24px 32px" src="@/assets/images/solution/citySafetyOperationPlatform/10.png" alt="" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 190px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.text1 {
  padding: 0 32px;
  text-indent: 2em;
  line-height: 25px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
}
.itemWrap {
  padding: 26px 32px 0;
  display: flex;
  justify-content: space-between;
  .item {
    position: relative;
    img {
      width: 150px;
      height: 32px;
    }
    .desc {
      position: absolute;
      width: 78px;
      top: 8px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
}
.itemWrap1 {
  padding: 26px 32px 0;
  display: flex;
  justify-content: space-between;
  .item {
    position: relative;
    img {
      width: 100px;
      height: 100px;
    }
    .desc {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      color: white;
      font-size: 12px;
      // letter-spacing: 5px;
    }
  }
}
</style>
