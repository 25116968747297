<template>
  <div>
    <div class="title1">一中心</div>
    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>一中心：城市安全运行监测中心</span>
    </div>

    <img style="width: calc(100% - 64px); padding: 0 32px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/center/01.png" alt="" />

    <div class="boxWrap">
      <div class="box box1">
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/center/02.png" alt="" />
        <p>7*24小时全天候监测和预警</p>
      </div>
      <div class="box box2">
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/center/03.png" alt="" />
        <p>
          <span>值守及分析队伍</span>
          <span>软硬件运维保障</span>
        </p>
      </div>
    </div>

    <div class="boxWrap1">
      <div class="box box1">
        <div class="header">服务范围</div>
        <div class="body">
          <span>服务政府部门以及供电、供水、燃气、桥梁、隧道、危化品、尾矿库等权属单位。</span>
          <div class="row">
            <div class="card">住建部</div>
            <div class="card">应急厅</div>
          </div>
          <div class="row">
            <div class="card">城管网</div>
            <div class="card">水利厅</div>
          </div>
          <div class="row">
            <div class="card" style="width: 120px">......</div>
          </div>
        </div>
      </div>
      <div class="box box2">
        <div class="header">服务时间</div>
        <div class="body">提供7*24小时监测值守、数据分析、辅助决策支持和系统运行保障报务。</div>
      </div>
      <div class="box box3">
        <div class="header">服务内容</div>
        <div class="body">推送风险警情、安全险患分析报告，定期提供城市安全风险综合分析月报、季报和年报。</div>
      </div>
      <img class="icon icon1" src="@/assets/images/serverSystem/center/04.png" alt="" />
      <img class="icon icon2" src="@/assets/images/serverSystem/center/05.png" alt="" />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 120px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.boxWrap {
  padding: 26px 32px 0;
  display: flex;
  .box {
    width: 50%;
    height: 200px;
    img {
      width: 100%;
      height: 163px;
    }
    p {
      height: 28px;
      line-height: 28px;
      margin-top: -4px;
      background: #83619b;
      display: flex;
      justify-content: space-around;
      color: white;
      font-size: 12px;
    }
  }
  .box1 {
    margin-right: 16px;
  }
  .box2 {
    margin-left: 16px;
  }
}
.boxWrap1 {
  border: 1px dashed #83619b;
  width: calc(100% - 64px);
  margin: 20px auto;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  .box {
    border: 1px solid #83619b;
    margin: 16px 0;
    width: 195px;
    position: relative;
    .header {
      background: #83619b;
      text-align: center;
      color: white;
      font-size: 12px;
      height: 26px;
      line-height: 26px;
    }
    .body {
      font-size: 12px;
      font-weight: 100;
      padding: 12px;
      .row {
        display: flex;
        justify-content: space-around;
        margin: 8px 0;
        .card {
          width: 72px;
          height: 20px;
          line-height: 20px;
          background: #f3f0f7;
          color: #83619b;
          border-radius: 3px;
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
  .icon {
    width: 20px;
    height: 2px;
  }
  .icon1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: -1px;
    bottom: -1px;
  }
  .icon2 {
    position: absolute;
    width: 30px;
    height: 30px;
    right: -1px;
    top: -1px;
  }
}
</style>
