<template>
  <div>
    <div class="title1">公司介绍</div>

    <img style="display: block; width: calc(100% - 64px); margin: 32px auto 0" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/aboutUs/companyIntroduction/01.png" />
    <div class="bottom">
      <p class="text1">企业简介</p>
      <p class="text2">COMPANY PROFILE</p>
    </div>

    <div class="titleWrap">
      <img src="@/assets/images/aboutUs/companyIntroduction/02.png" />
      <span>定位：城市安全行业数字化解决方案专家</span>
    </div>

    <div class="pointWrap">
      <div class="point"></div>
      <div class="desc">江西城市生命线科技有限公司是泰豪集团与城市生命线产业集团合资成立的城市安全行业整体解决方案提供商。</div>
    </div>
    <div class="pointWrap">
      <div class="point"></div>
      <div class="desc">
        依托清华大学公共安全研究院在城市安全领域积累的城市高风险空间识别、跨系统风险转移和耦合灾害分析等核心关键技术,充分融合5G、物联网、AI、VR、云计算、大数据等新技术,面向应急、消防、住建、城管、市政、水利、交通等行业,围绕安全与应急两大核心主题,就“隐患排查、风险识别、监测预警、应急处置联动”等核心问题提供一站式解决方案服务,实现城市安全运行管理“从看不见向看得见、从事后调查处理向事前事中预警、从被动应对向主动防控”的根本转变
        。
      </div>
    </div>
    <div class="pointWrap">
      <div class="point"></div>
      <div class="desc">通过技术创新和数据赋能,促进生态企业实现提质、降本、增效,推动城市安全产业高质量发展，为安全中国、数字中国、幸福中国的建设提供科技和产业支撑。</div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 130px;
    height: 1px;
    background: #83619b;
  }
}
.bottom {
  width: calc(100% - 64px);
  margin: 0 auto;
  background: #584a83;
  height: 60px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-indent: 90px;
  .text1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
    letter-spacing: 2px;
  }
  .text2 {
    font-size: 13px;
    font-weight: 100;
  }
}
.titleWrap {
  padding-left: 32px;
  display: flex;
  align-items: center;
  margin: 32px 0;
  img {
    width: 30px;
    height: 35px;
    padding: 0 16px 0 80px;
  }
  span {
    font-size: 18px;
    font-weight: 700;
    color: #584a83;
  }
}
.pointWrap {
  width: calc(100% - 200px);
  margin: 16px 0 16px 122px;
  display: flex;
  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #584a83;
    margin: 4px 27px 0 0;
  }
  .desc {
    flex: 1;
    font-size: 14px;
    font-weight: 10;
    color: #676767;
    letter-spacing: 2px;
    line-height: 25px;
  }
}
</style>
