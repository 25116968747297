<template>
  <div ref="appRef" :style="`transform: scale(${scale})`">
    <router-view />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount, nextTick } from 'vue'

const scale = reactive({
  width: '1',
  height: '1',
})

const baseWidth = 1920
const baseHeight = 1080
const appRef = ref(null)
const drawTiming = ref(null)

const calcRate = () => {
  if (!appRef.value) return // 确保 appRef.value 存在

  // 基于宽度计算比例
  scale.width = (window.innerWidth / baseWidth).toFixed(5)
  scale.height = scale.width // 保持宽高一致的比例
  console.log(scale.width, scale.height, '按宽度缩放')

  // 设置缩放
  appRef.value.style.transform = `scale(${scale.width})`

  // 设置 transform-origin 为 "top left"，确保缩放从左上角开始
  appRef.value.style.transformOrigin = 'top left'

  nextTick(() => {
    // 固定 appRef 的宽高为设计稿尺寸
    appRef.value.style.width = `${baseWidth}px`
    appRef.value.style.height = `${baseHeight}px`
  })
}

const resize = () => {
  clearTimeout(drawTiming.value)
  drawTiming.value = setTimeout(() => {
    calcRate()
  }, 200)
}

onMounted(() => {
  // calcRate()
  // window.addEventListener('resize', resize)
})

onBeforeUnmount(() => {
  // window.removeEventListener('resize', resize)
})
</script>

<style lang="scss"></style>
