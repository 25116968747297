<template>
  <div class="menuContainer">
    <div class="menuItem" :class="{ active: index === menuIndex }" v-for="(item, index) in menuList" @click="menuChange(index)">
      {{ item }}
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const emit = defineEmits(['change'])

const menuList = ref(['总概','城市安全运营平台', '城市生命线专项', '公共安全专项', '生产安全专项', '自然灾害专项', '城市安全应急演练'])
const menuIndex = ref(0)

const menuChange = (e) => {
  if (menuIndex.value === e) return
  menuIndex.value = e
  emit('change', menuList.value[e])
}
</script>

<style lang="scss" scoped>
.menuContainer {
  width: 200px;
  height: 278px;
  background: white;
  .menuItem {
    font-size: 12px;
    font-weight: 100;
    height: 30px;
    line-height: 30px;
    text-indent: 30px;
    letter-spacing: 2px;
    cursor: pointer;
  }
  .active {
    color: #fcfcfb;
    background: #83619b;
  }
}
</style>
