<template>
  <div class="title1">一平台</div>
  <div class="title2">
    <img src="@/assets/images/common/titleIcon1.png" />
    <span>一中心：城市安全监测运营平台</span>
  </div>

  <img style="width: calc(100% - 64px); padding: 0 32px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/platform/01.png" alt="" />

  <div class="title2">
    <img src="@/assets/images/common/titleIcon2.png" />
    <span>平台优势</span>
  </div>

  <div class="boxWrap">
    <div class="box">
      <div class="num">01</div>
      <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/platform/02.png" alt="" />
      <div class="right">
        <p class="title">售前灾害</p>
        <p class="desc">现场调研规划、解决方案设计、技术指导</p>
      </div>
    </div>
    <div class="box">
      <div class="num">02</div>
      <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/platform/02.png" alt="" />
      <div class="right">
        <p class="title">售后服务</p>
        <p class="desc">系统运维服务、现场技术服务、远程服务(电话、微信)</p>
      </div>
    </div>
    <div class="box">
      <div class="num">03</div>
      <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/platform/02.png" alt="" />
      <div class="right">
        <p class="title">产品培训</p>
        <p class="desc">内部培训、外部培训</p>
      </div>
    </div>
    <div class="box">
      <div class="num">04</div>
      <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/serverSystem/platform/02.png" alt="" />
      <div class="right">
        <p class="title">市场支持</p>
        <p class="desc">硬件设备支持与协调、资源适配软硬件协调、会议活动支持</p>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 120px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.boxWrap {
  width: calc(100% - 64px);
  padding: 0 32px;
  display: flex;
  flex-wrap: wrap;
  .box {
    width: 50%;
    display: flex;
    margin-bottom: 32px;
    position: relative;
    .num {
      position: absolute;
      left: 58px;
      top: 23px;
      color: white;
    }
    img {
      width: 133px;
      height: 100px;
    }
    .right {
      margin-left: 32px;
      .title {
        color: #584a83;
        font-weight: 600;
      }
      .desc {
        margin-top: 16px;
        width: 160px;
        font-weight: 100;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
