<template>
  <div class="containerWrap">
    <div class="bgWrap">
      <img class="bg" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/common/headerBg.png" />
      <p class="bgTitle1">专注于城市安全</p>
      <p class="bgTitle2">坚守科技报国 创新赋能安全</p>
    </div>
    <div class="desc">
      <img class="location" src="@/assets/images/common/location.png" />
      <p class="text">应用案例</p>
    </div>

    <div class="container">
      <div class="content">
        <!-- 左侧菜单 -->
        <Menu @change="menuChange" style="margin: 0 16px 0 0" />
        <!-- 右侧内容 -->
        <div class="right">
          <GeneralView v-if="menuActive === '总览'" />
          <HeFeiCase v-if="menuActive === '合肥案例'" />
          <AnHuiCase v-if="menuActive === '安徽案例'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Menu from './components/menu.vue'
import GeneralView from './components/generalView.vue'
import HeFeiCase from './components/heFeiCase.vue'
import AnHuiCase from './components/anHuiCase.vue'
import { ref } from 'vue'

const menuActive = ref('总览')

const menuChange = (e) => {
  menuActive.value = e
}
</script>

<style lang="scss" scoped>
.containerWrap {
  min-height: calc(100vh - 170px);
  overflow-y: scroll;
  margin: 0 auto 0;
  .bgWrap {
    min-width: 1200px;
    position: relative;
    .bg {
      width: 100%;
    }
    .bgTitle1 {
      position: absolute;
      top: 50%;
      left: 360px;
      transform: translate3d(0, calc(-50% - 24px), 0);
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 5px;
      color: white;
    }
    .bgTitle2 {
      position: absolute;
      top: 50%;
      left: 360px;
      transform: translate3d(0, calc(-50% + 24px), 0);
      font-size: 14px;
      font-weight: 100;
      letter-spacing: 2px;
      color: white;
    }
  }
  .desc {
    width: 1200px;
    min-width: 1200;
    margin: 0 auto;
    display: flex;
    padding-top: 8px;
    height: 40px;
    .location {
      width: 16px;
      height: 20px;
      margin: 0 8px 0 0;
    }
    .text {
      color: #83619b;
      font-weight: 600;
      font-size: 17px;
      letter-spacing: 1px;
    }
  }

  .container {
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    background: #f3f3f5;
    .content {
      padding: 16px;
      display: flex;
      background: #f3f3f5;
      .right {
        flex: 1;
        background: white;
      }
    }
  }
}
</style>
