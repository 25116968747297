<template>
  <div>
    <div class="title1">总概</div>

    <div class="content">
      <div class="left">
        <div class="title">“1+1+1+N”产品架构体系</div>
        <div class="text">
          以城市安全监测业务为核心，由统一平台框架物联网平台、AI大模型中台、大数据中台共同构成的专业数字安全底座，以全行业应急安全演练培训和宣传教育为辅助支撑“软”手段，服务于城市生命线、公共安全、生产安全、自然灾害等各专项领域，以监测运营中心为支撑，建立全域覆盖、全天候运行的城市安全风险监测运营平台，实现城市安全风险评估、动态监测、综合研判、联动处置等全业务链应用,构建统分结合、协调联动的城市安全风险监测预警体系，有效防范事故发生，保障城市基础设施安全运行。
        </div>
      </div>
    </div>
    <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/grandScheme/01.png" alt="" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 95px;
    height: 1px;
    background: #83619b;
  }
}
.content {
  margin: 36px 32px;
  display: flex;
  .left {
    .title {
      color: #83619b;
      font-size: 17px;
      font-weight: 600;
    }
    .text {
      margin-top: 18px;
      text-indent: 2em;
      line-height: 25px;
      font-size: 14px;
      letter-spacing: 2px;
      color: #676767;
    }
  }
  img {
    padding-top: 80px;
    width: calc(100% - 64px);
    padding: 16px 0 16px 32px;
  }
}
</style>
