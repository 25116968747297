<template>
  <div>
    <div class="title1">总览</div>

    <div class="top">
      <div class="left">
        <p style="font-size: 18px; letter-spacing: 4px; color: #584a85">全国案例</p>
        <p style="font-size: 10px; color: #584a85">NATIONAL CASES</p>
      </div>
      <!-- <img src="@/assets/images/applicationCase/generalView/01.png" style="width: 90px; height: 32px" /> -->
    </div>

    <div class="top1">
      <img class="img1" src="@/assets/images/applicationCase/generalView/02.png" />
      <img class="img2" src="@/assets/images/applicationCase/generalView/04.png" />
      <img class="img3" src="@/assets/images/applicationCase/generalView/03.png" />
      <div class="text text1">已布局全国共计</div>
      <div class="text text2">65+</div>
      <div class="text text3">个城市</div>
    </div>

    <img style="display: block; width: calc(100% - 100px); margin: 32px auto" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/applicationCase/generalView/05.png" alt="" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 95px;
    height: 1px;
    background: #83619b;
  }
}
.top {
  width: calc(100% - 64px);
  padding: 12px 32px 36px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.top1 {
  width: calc(100% - 64px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  .img1 {
    width: 230px;
    height: 1px;
    padding-top: 25px;
  }
  .img2 {
    width: 130px;
    height: 50px;
    padding-left: 18px;
  }
  .img3 {
    width: 230px;
    height: 1px;
    padding-top: 25px;
  }
  .text {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    color: #584a83;
    font-weight: 100;
    font-size: 14px;
  }
  .text1 {
    left: 280px;
    font-size: 14px;
  }
  .text2 {
    left: 455px;
    font-size: 17px;
    font-weight: 700;
  }
  .text3 {
    left: 510px;
    font-size: 14px;
  }
}
</style>
