import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Index from '@/views/index/index.vue'
import Home from '@/views/home/home.vue'
import Solution from '@/views/solution/solution.vue'
import ServerSystem from '@/views/serverSystem/serverSystem.vue'
import ApplicationCase from '@/views/applicationCase/applicationCase.vue'
import AboutUs from '@/views/aboutUs/aboutUs.vue'


const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: Home,
      },
      // 服务体系
      {
        path: 'serverSystem',
        name: 'ServerSystem',
        component: ServerSystem,
      },
      // 解决方案
      {
        path: 'solution',
        name: 'solution',
        component: Solution,
      },
      // 应用案例
      {
        path: 'applicationCase',
        name: 'applicationCase',
        component: ApplicationCase,
      },
      // 关于我们
      {
        path: 'aboutUs',
        name: 'aboutUs',
        component: AboutUs,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
