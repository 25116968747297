<template>
  <div class="footerContainer">
    <div class="content">
      <div class="col">
        <div class="title">国务院相关部门网站</div>
        <div class="itemWrap">
          <div class="left">
            <div class="item" @click="openPage('https://www.mem.gov.cn')">应急管理部</div>
            <div class="item" @click="openPage('https://www.mohurd.gov.cn/')">住房和城乡建设部</div>
            <div class="item" @click="openPage('https://www.mem.gov.cn/awhsy_3512/')">国务院安委会办公室</div>
            <div class="item" @click="openPage('https://www.MEE.GOV.CN/')">生态环境部</div>
            <div class="item" @click="openPage('https://www.MOT.GOV.CN/')">交通运输部</div>
            <div class="item" @click="openPage('https://www.MPS.GOV.CN/')">公安部</div>
          </div>
          <div class="right">
            <div class="item" @click="openPage('https://www.MOST.GOV.CN/index.html')">科学技术部</div>
            <div class="item" @click="openPage('https://www.CEA.GOV.CN/')">中国地震局</div>
            <div class="item" @click="openPage('https://www.CMA.GOV.CN/')">中国气象局</div>
            <div class="item" @click="openPage('https://www.119.GOV.CN/')">消防救援局</div>
            <div class="item" @click="openPage('https://www.MNR.GOV.CN/')">自然资源部</div>
            <div class="item" @click="openPage('https://www.NDRCC.ORG.CN/')">国家减灾网</div>
          </div>
        </div>
      </div>

      <div style="margin-left: 200px">
        <div class="col">
          <div class="title">科研事业支持</div>
          <div class="itemWrap">
            <div class="left">
              <div class="item" @click="openPage('https://www.TSINGHUA.EDU.CN')">清华大学</div>
              <div class="item" @click="openPage('https://www.tsinghua-hf.edu.cn/')">清华大学合肥公共安全研究院</div>
              <div class="item" @click="openPage('https://www.tellhow.cn/')">泰豪集团有限公司</div>
              <div class="item" @click="openPage('http://www.urbanlifeline.com/')">城市生命线产业发展集团（安徽）有限公司</div>
            </div>
          </div>
        </div>
      </div>
      <img class="qrCode" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/common/qrCode.jpg" alt="" />
      
    </div>

    <div class="benAn" style="margin-top: 48px; font-size: 14px; color: #5c5c5c; cursor: pointer">
      <div @click="openPage('https://beian.miit.gov.cn/')">©2024 江西城市生命线科技有限公司<span style="padding: 0 24px 0 16px">|</span> 赣ICP备2024043550号-1 赣公网安备 36012102000728号</div>
    </div>
  </div>
</template>

<script setup>
const openPage = (url) => {
  window.open(url)
}
</script>

<style lang="scss" scoped>
.footerContainer {
  width: 1200px;
  margin: 0 auto 100px;
  .content {
    display: flex;
    position: relative;
    color: #5c5c5c;
    padding-left: 20px;
    .qrCode {
      width: 120px;
      height: 120px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
    .col {
      margin-top: 32px;
      text-align: left;
      .title {
        color: #5c5c5c;
        font-size: 18px;
        margin-bottom: 32px;
      }

      .itemWrap {
        display: flex;
        .item {
          font-size: 14px;
          margin-bottom: 12px;
          cursor: pointer;
        }
        .left {
          margin-right: 48px;
        }
      }
    }
  }
  .benAn {
    padding-left: 20px;
  }
}
</style>
