<template>
  <div>
    <div class="title1">城市安全应急演练</div>

    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>应急演练培训平台</span>
    </div>
    <div class="desc">
      应急演练培训平台围绕着“轻量化”、“常态化”、“多场景”，遵循“先单项后综合、先桌面后实战、循序渐进、时空有序”，以“工具+服务”的服务方，基于点面演练形式，通过三维仿真打造情景可视化的演练方案，将丰富的安全应急知识贯穿到演练流程当中，形成全新的“培训+演练”模式，利用计算机进行摄作，并严格按照演练准备、组织动员演练实施、总结评估的流程，严格执行标准化服务，以练促学让演练更高效。
    </div>
    <div style="width: calc(100% - 64px); padding: 12px 32px 36px; display: flex; justify-content: space-between">
      <img style="width: 355px; height: 230px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citySafetyEmergencyDrill/01.png" alt="" />
      <img style="width: 355px; height: 230px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citySafetyEmergencyDrill/02.png" alt="" />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 190px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.desc {
  width: calc(100% - 64px);
  padding: 0 32px;
  text-indent: 2em;
  line-height: 23px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
}
</style>
