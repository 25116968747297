<template>
  <div class="homeContainer">
    <!-- <swiper
      style="height: 100%"
      :space-between="10"
      :observer="true"
      :observeParents="true"
      :centeredSlides="true"
      :loop="true"
      :modules="modules"
      :speed="1000"
      :autoplay="autoConfig.info"
      :slideToClickedSlide="true"
      :pagination="{ clickable: true }"
    >
      <swiper-slide
        ><div class="banner">
          <img src="@/assets/images/home/banner1.jpg" style="width: 100%; height: 100%" />
          <div class="content">
            <p class="title title1">国内首款城市生命线操作系统</p>
            <p class="title title2">专注于城市安全领域 | 分布式操作系统</p>
            <p class="title title3">
              运用工业互联网架构,基于物联网、大数据、云计算、大模型等技术,充分融合城市安全领域积累的科研成果,赋能生态企业实现提质、降本、增效,实现城市安全应用一次构建多域部署,有效解决行业场景众多,难以有效
            </p>
            <p class="title title4">监管,技术监测困难、缺乏统一支撑,烟囱系统林立、数据孤岛从生的核心诉求</p>
          </div>
        </div></swiper-slide
      >
      <swiper-slide><img class="banner" src="@/assets/images/home/banner2.jpg" style="width: 100%; height: 100%" /></swiper-slide>
      <swiper-slide> <img class="banner" src="@/assets/images/home/banner3.jpg" style="width: 100%; height: 100%" /> </swiper-slide>
    </swiper> -->
    <el-carousel height="560px" trigger="click">
      <el-carousel-item>
        <div class="banner">
          <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/home/banner1.jpg" style="width: 100%; height: 100%" />
          <div class="content">
            <p class="title title1">国内首款城市生命线操作系统</p>
            <p class="title title2">专注于城市安全领域 | 分布式操作系统</p>
            <p class="title title3">
              运用工业互联网架构,基于物联网、大数据、云计算、大模型等技术,充分融合城市安全领域积累的科研成果,赋能生态企业实现提质、降本、增效,实现城市安全应用一次构建多域部署,有效解决行业场景众多,难以有效
            </p>
            <p class="title title4">监管,技术监测困难、缺乏统一支撑,烟囱系统林立、数据孤岛从生的核心诉求</p>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="banner">
          <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/home/banner2.png" style="width: 100%; height: 100%" />

          <!-- <div class="content1">
            <div class="left">
              <p class="title title1">"1+1+1+N"产品架构体系</p>
              <p class="title title3">
                城市安全风险监测运营以城市安全监测业务为核心,由统一平台框架、物联网平台、AI大模型中台、大数据中台共同构成的专业数字安全底座，以全行业应急安全演练培训和宣传教育为辅助支撑“软”手段,服务于城市生命线、公共安全、生产安全、自然灾害等各专项领域，以监测运营中心为支撑,建立全域覆盖、全天候运行的城市安全风险监测运营平台,实现城市安全风险评估、动态监测、综合研判、联动处置等全业务链应用,构建统分结合、协调联动的城市安全风险监测预警体系,有效防范事故发生，保障城市基础设施安全运行。
              </p>
            </div>
            <img src="https://i.ibb.co/hWHXL7m/01.png" alt="" class="rightImg" /> -->
          <!-- <div class="right">
              <div class="box1">城市安全运行监测中心(实体)</div>
              <div class="box2">
                <p class="title">监测运营平台</p>
                <div class="textWrap">
                  <p class="text">风险监测运营平台</p>
                  <p class="text">预警联动处置平台</p>
                  <p class="text">运营运维管理平台</p>
                </div>
              </div>
              <div class="box3">
                <div class="block">
                  <p>城市生命线</p>
                  <p class="text">燃气、桥梁、供排水、综合管廊...</p>
                </div>
                <div class="block">
                  <p>生产安全</p>
                  <p class="text">危化品生产、尾矿库溃坝、水库溃坝....</p>
                </div>
                <div class="block">
                  <p>公共安全</p>
                  <p class="text">老旧房屋、人员密集区域、建筑火灾...</p>
                </div>
                <div class="block">
                  <p>自然灾害</p>
                  <p class="text">森林火灾、地震地址灾害、城市洪涝...</p>
                </div>
              </div>
              <div class="box4">
                <p class="text">统一平台框架</p>
                <p class="text"></p>
                <p class="text">AI大模型中台</p>
                <p class="text">物联网平台</p>
                <p class="text">应急演练培训平台</p>
                <p class="text">大数据中台</p>
              </div>
            </div> -->
          <!-- </div> -->
        </div>
        /></el-carousel-item
      >
      <el-carousel-item><img class="banner" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/home/banner3.jpg" style="width: 100%; height: 100%" /> </el-carousel-item>
    </el-carousel>

    <div class="cooperation">
      <div class="left">
        <p class="text1">近期新闻</p>
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/home/cooperation1.jpg" style="width: 100%; height: 100%" />
      </div>
      <div class="right">
        <div class="date">2024年10月28日</div>
        <p class="text2">江西省应急管理厅与泰豪集团战略合作签约仪式</p>
        <p class="text3">
          <span class="point"></span
          >2024年10月28日，江西省应急管理厅与泰豪集团签署战略合作协议签约仪式在泰豪科教园朱子书院正式举行。江西省应急管理厅党委书记、厅长徐卫明亲自带队，厅党委委员、副厅长徐何明以及厅办公室、救援协调和预案管理局、科信处、省应急管理学院等核心领导团队莅临现场，共同见证这一历史时刻。
        </p>
        <div class="btn" @click="openPage('https://mp.weixin.qq.com/s/TdK9d5IJpnmlxuuzsPnwqg')">
          <el-icon style="padding-right: 5px"><ArrowRightBold /></el-icon>阅读全文
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination } from 'swiper'
import { ArrowRightBold } from '@element-plus/icons-vue'
import 'swiper/css'
import 'swiper/css/pagination'

const modules = [Autoplay, Pagination] //Autoplay 自动播放  Pagination 分页
//自动轮播配置
const autoConfig = reactive({
  info: {
    delay: 3000, //间隔时间
    disableOnInteraction: false, //设置为false，用户交互（滑动）后自动播放不会被禁用，每次交互后都会重新启动
    reverseDirection: false, //是否反方向轮播
    stopOnLastSlide: false, //执行到最后
    pauseOnMouseEnter: false, //鼠标输入时暂停
  },
})

const openPage = (url) => {
  window.open(url, '_blank')
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    .title {
      color: white;
      letter-spacing: 4px;
      text-align: center;
    }
    .title1 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .title2 {
      font-size: 16px;
      margin-bottom: 50px;
      letter-spacing: 2px;
    }
    .title3,
    .title4 {
      letter-spacing: 2px;
      font-size: 14px;
      font-weight: 100;
      margin-bottom: 16px;
      color: #eeedef;
    }
  }
  .content1 {
    display: flex;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #4f385a, #c368be);
    position: relative;
    .left {
      width: 400px;
      position: absolute;
      top: 50%;
      // left: calc(50% - 500px);
      left: calc(200px);
      transform: translate3d(0, -50%, 0);
      .title {
        color: #eeedef;
        letter-spacing: 4px;
        text-align: left;
      }
      .title1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 18px;
        letter-spacing: 2px;
      }
      .title2 {
        font-size: 16px;
        margin-bottom: 40px;
        letter-spacing: 2px;
      }
      .title3 {
        line-height: 26px;
        letter-spacing: 2px;
        font-size: 14px;
        font-weight: 100;
        margin-bottom: 16px;
        color: #eaeaed;
      }
    }
    .rightImg {
      width: 1000px;
      height: 530px;
      position: absolute;
      top: 50%;
      left: 700px;
      transform: translate3d(0, -50%, 0);
    }
    .right {
      width: 400px;
      position: absolute;
      top: 105px;
      left: calc(50% + 500px);
      transform: translate3d(-50%, 0, 0);
      color: #eeedef;
      .box1 {
        width: 500px;
        height: 58px;
        padding: 16px 32px;
        box-sizing: border-box;
        background: #8583c4;
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 2px;
      }
      .box2 {
        width: 500px;
        height: 76px;
        padding: 16px 32px;
        box-sizing: border-box;
        background: #9279cd;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        .title {
          font-weight: 600;
          font-size: 17px;
        }
        .textWrap {
          margin-top: 6px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          letter-spacing: 1px;
          .text {
            font-size: 11px;
          }
        }
      }
      .box3 {
        width: 500px;
        height: 144px;
        padding: 16px 32px;
        box-sizing: border-box;
        background: #8c76c8;
        letter-spacing: 2px;
        font-size: 17px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;
        .block {
          width: 50%;
          p {
            font-weight: 600;
          }
          .text {
            margin-top: 12px;
            font-size: 11px;
            letter-spacing: 1px;
          }
        }
      }
      .box4 {
        width: 500px;
        height: 110px;
        padding: 16px 32px;
        box-sizing: border-box;
        background: #9456c3;
        font-size: 17px;
        letter-spacing: 2px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;
        .text {
          width: 50%;
          font-weight: 600;
        }
      }
    }
  }
}
.cooperation {
  width: 1200px;
  margin: 64px auto 32px;
  display: flex;
  align-items: center;
  .left {
    width: 35%;
    .text1 {
      font-size: 30px;
      letter-spacing: 1px;
      margin-bottom: 30px;
      color: #83619b;
    }
  }
  .right {
    width: 65%;
    box-sizing: border-box;
    padding-left: 2em;
    .date {
      font-size: 18px;
      letter-spacing: 1px;
      color: #83619b;
      margin-top: 24px;
    }
    .text2 {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 4px;
      padding: 24px 0 18px 0;
      color: #83619b;
    }
    .text3 {
      font-size: 14px;
      letter-spacing: 2px;
      color: #5c5c5c;
      line-height: 25px;
      .point {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #83619b;
        margin: 0 9px 3px 0;
      }
    }
    .btn {
      background: #83619b;
      width: 120px;
      height: 34px;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 14px;
      letter-spacing: 2px;
      font-weight: 400;
      margin-top: 16px;
      cursor: pointer;
    }
  }
}
</style>
