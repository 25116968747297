<template>
  <div>
    <div class="title1">合肥案例</div>

    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />

      <span>合肥案例</span>
    </div>

    <div class="desc">
      自2015年，安徽省合肥市坚持问题导向，以风险评估为建设依据，统筹规划、有序推进，逐步建立起城市生命线安全工程，截至当前,已全部覆盖合肥市全域，通过城市生命线安全监测运营平台预防各类突发险情6000多起事故发生率下降60%、风险排查效率提高70%。
    </div>

    <img style="display: block; width: calc(100% - 64px); margin: 32px auto" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/applicationCase/heFeiCase/01.png" alt="" />

    <div class="title2">
      <img src="@/assets/images/common/titleIcon2.png" />
      <span>应用成效</span>
    </div>

    <div style="width: calc(100% - 64px); margin: 32px auto; display: flex; justify-content: space-around">
      <img style="width: 46%" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/applicationCase/heFeiCase/02.png" alt="" />
      <img style="width: 46%" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/applicationCase/heFeiCase/03.png" alt="" />
    </div>

    <div class="textWrap">
      <span class="text1">监测覆盖地下管线</span>
      <span class="text2">7316</span>
      <span class="text1">公里，桥梁</span>
      <span class="text2">137</span>
      <span class="text2">座</span>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 125px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.desc {
  width: calc(100% - 64px);
  padding: 0 32px;
  text-indent: 2em;
  line-height: 25px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
}
.textWrap {
  display: flex;
  justify-content: center;
  margin: 64px 0;
  .text1 {
    color: #83619b;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .text2 {
    color: #d9a160;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 2px;
  }
}
</style>
