<template>
  <div>
    <div class="title1">城市生命线专项</div>

    <div class="title2">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>燃气管网监测预警系统</span>
    </div>
    <div class="boxWrap">
      <img class="left" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citylifelineProject/01.jpg" alt="" />
      <div class="right">
        <div class="text">
          运用物联网、大数据、云计算等信息技术，通过可燃气体探测器、智能光谱气体泄漏监测仪、燃气阀门等终端设备对工商业、家用燃气、埋地管网及地井进行实时监测，实现对城市燃气泄漏的监测管理。
        </div>
        <div class="bottom">
          <div class="item">
            <img src="@/assets/images/solution/citylifelineProject/02.png" alt="" />
            <span>实时监测</span>
          </div>
          <div class="item">
            <img src="@/assets/images/solution/citylifelineProject/03.png" alt="" />
            <span>报警预警</span>
          </div>
          <div class="item">
            <img src="@/assets/images/solution/citylifelineProject/04.png" alt="" />
            <span>处置跟踪</span>
          </div>
        </div>
      </div>
    </div>

    <div class="title2" style="margin: 48px 0 0 32px">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>液化气钢瓶安全监管系统</span>
    </div>
    <div class="boxWrap">
      <div class="left" style="padding-top: 32px">
        <div class="text">
          液化气钢瓶安全监管平台通常集成了物联网（IOT）、云计算、池理信息系统（GIS）、大数据分析等先进技术，形成一个从生产、充装、存储、运输、配送到终端用户使用的全链条监管体系。系统能够实时监测钢瓶的流转状态、使用情况及安全性，确保液化气的供应链透明化、可追湖性，同时提高对潜在风险的预警与应急响应能力。
        </div>
      </div>
      <div class="right" style="margin-left: 80px">
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citylifelineProject/05.png" alt="" style="width: 100%; height: 300px" />
      </div>
    </div>

    <div class="title2" style="margin-top: 48px">
      <img src="@/assets/images/common/titleIcon1.png" />
      <span>供水管网监测预警系统</span>
    </div>
    <div class="boxWrap">
      <div class="left">
        <div class="text">
          通过压力计、流量计、水质监测及漏失监测等设备，利用物联网、大据等专业技术获取实时数据与智能分析，实现供水微小泄漏、爆管监测报警，防止因供水泄漏导致的地下空洞、路面塌陷等问题，保障供水管网运行安全。
        </div>
      </div>
      <div class="right">
        <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citylifelineProject/06.png" alt="" style="width: 100%; height: 200px" />
      </div>
    </div>

    <div class="title2" style="margin-top: 48px">
      <img src="@/assets/images/common/titleIcon2.png" />
      <span>内涝预测预警监测系统</span>
    </div>
    <div class="desc">
      内涝预测预警系统是基于物联网与大数据技术的城市防洪排涝管理系统，通过实时监测降雨量、水位、流速等关键指标，运用模型预测内涝风险，及时发出预警信息，指导相关部门提前采取应对措施。该系统有效提升城市应对极端天气的能力，减少内涝灾害带来的财产损失和人员伤亡，保障城市运行安全，同时促进城市管理智能化和决策科学化，为构建韧性城市提供强有力的技术支撑。
    </div>
    <img style="width: calc(100% - 64px); padding: 12px 32px 36px" src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/solution/citylifelineProject/07.png" alt="" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 170px;
    height: 1px;
    background: #83619b;
  }
}
.title2 {
  margin: 36px 0 36px 32px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    color: #584a83;
    letter-spacing: 2px;
  }
}
.boxWrap {
  width: calc(100% - 64px);
  padding: 0 32px;
  display: flex;
  .left {
    width: 450px;
    height: 170px;
    .text {
      text-indent: 2em;
      line-height: 23px;
      font-size: 14px;
      letter-spacing: 2px;
      color: #676767;
    }
  }
  .right {
    margin-left: 32px;
    .text {
      text-indent: 2em;
      line-height: 23px;
      font-size: 14px;
      letter-spacing: 2px;
      color: #676767;
    }
    .bottom {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .item {
        position: relative;
        width: 120px;
        height: 60px;
        border: 1px dashed #83619b;
        img {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translate3d(0, -50%, 0);
          width: 27px;
          height: 27px;
        }
        span {
          line-height: 60px;
          color: #83619b;
          font-size: 14px;
          letter-spacing: 2px;
          padding-left: 44px;
        }
      }
    }
  }
}
.desc {
  width: calc(100% - 64px);
  padding: 0 32px;
  text-indent: 2em;
  line-height: 23px;
  font-size: 14px;
  letter-spacing: 2px;
  color: #676767;
}
</style>
