<template>
  <div>
    <div class="title1">联系我们</div>

    <div class="contentWrap">
      <div class="left">
        <div class="text">公司地址：江西南昌小蓝经济开发区汇仁大道1333号</div>
        <div class="text">联系电话：13006206641/13277055622</div>
        <div class="text">通讯邮箱：cssmx@tellhow.com</div>
      </div>
      <img src="https://urban-lifeline-ui.oss-cn-hangzhou.aliyuncs.com/images/aboutUs/contactUs/01.png" alt="" />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.title1 {
  color: #584a83;
  font-size: 14px;
  position: relative;
  padding: 12px 0 0 32px;
  letter-spacing: 2px;
  &::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    width: 130px;
    height: 1px;
    background: #83619b;
  }
}
.contentWrap {
  // margin: 32px 0 200px 0;
  margin-top: 32px;
  display: flex;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #83619b;
    padding-left: 60px;
    margin-right: 16px;
    font-size: 14px;
    font-weight: 100;
    .text {
      margin: 8px 0;
    }
  }
  img {
    width: 380px;
    height: 205px;
  }
}
</style>
